"use strict";
import { useAccount } from "hooks/useAccount";
import { useEthersProvider } from "hooks/useEthersProvider";
import { useMemo } from "react";
import { UniverseChainId } from "uniswap/src/types/chains";
export function useWeb3React() {
  const account = useAccount();
  const provider = useEthersProvider({ chainId: account.chainId });
  return useMemo(
    () => ({
      account: account.address,
      chainId: account.chainId ?? UniverseChainId.AbstractTestnet,
      provider
    }),
    [account.address, account.chainId, provider]
  );
}
